<template>
  <UiPopup
    :model-value="modelValue"
    title="Reopen lead"
    :description="
      checking
        ? ''
        : canReopen
        ? 'If you want to reopen this lead, first indicate the reason. Lead will be Reopened and moved to the Interested stage.'
        : 'This lead was WON earlier and can\'t be reopened. Please create new lead to proceed work.'
    "
    :primary-button-text="checking ? '' : canReopen ? 'Reopen lead' : 'Create new lead'"
    :secondary-button-text="checking ? '' : canReopen ? 'Close' : 'Ok'"
    :loading="loading"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="canReopen ? submit() : emitCreate()"
  >
    <div>
      <UiLoader v-if="checking" />
      <form v-else-if="canReopen" ref="form" @submit.prevent>
        <UiInputTextField
          v-model="data.comment"
          name="ReasonToReopen"
          placeholder="Add a reason here"
          class="mb-4"
          :error="v$.comment.$errors[0] ? v$.comment.$errors[0].$message.toString() : ''"
        />
      </form>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Lead } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input', 'create'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const checking = ref(false)
const canReopen = ref(true)

const lead = computed(() => props.lead)

const { inInArchive } = useLeadStatus(lead)

onNuxtReady(async () => {
  checking.value = true
  try {
    const result = await useCheckLeadsAction(props.lead.id, 'reopen')
    canReopen.value = (result as any)?.data?.status

    if (inInArchive?.value) {
      // TODO: temporary fix for reopen lead in archive
      canReopen.value = true
    }
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    checking.value = false
  }
})

const loading = ref(false)
const data = ref({
  comment: '',
})

const rules = computed(() => ({
  comment: { required: helpers.withMessage('A comment is required', required) },
}))

const v$ = useVuelidate(rules, data)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  loading.value = true
  try {
    const lead = await useReopenLead(props.lead.id, data.value.comment)
    uiStore.showSnackBanner(`${props.lead.name} was reopened and moved to the Interested stage.`)
    emits('input', lead)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const emitCreate = () => {
  emits('create')
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
